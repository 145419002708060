<template>
  <div id="tiposexamen">
    <MenuBase
      :tipo="'tiposexamen'"
      :headers="headers"
      :items="$store.state.tabla"
      :botones="botones"
      :opciones="opciones"
      :alineaHeaders='alineaHeaders'
      :titulo="titulo"
      :tituloOpciones="tituloOpciones"
      :campoOpcion="campoOpcion"
      @clic-boton="clicBoton"
      @clic-fila="clicFila"
      :icono="'mdi-test-tube-empty'"
      :verTabla="true"
      :loading="!$store.state.tabla.length"
    />
    <TipoExamen :modo="modoTipoExamen" :dialogTE.sync="dialogTipoExamen" :ntipoexamen.sync="this.campoOpcion" :origen.sync="origen"/>
  </div>
</template>

<script>
import Globales from '@/mixins/globales.vue'
import MenuBase from '@/components/menubase.vue'
import TipoExamen from '@/views/tipoexamen.vue'

export default {
  mixins: [
    Globales
  ],
  components: {
    MenuBase,
    TipoExamen
  },
  data: () => ({
    headers: [],
    items: [],
    botones: [],
    opciones: [],
    titulo: 'Tipos Exámen',
    dialog: false,
    tituloOpciones: 'Opciones método: ',
    campoOpcion: '',
    alineaHeaders: 'start',
    modoTipoExamen: 'NUEVO',
    dialogTipoExamen: false,
    origen: 'tabla'
  }),
  methods: {
    interfaz () {
      this.headers = [
        { text: 'NOMBRE', align: 'start', value: 'nombre' },
        { text: 'NOTAS', align: 'start', value: 'procesado' },
        { text: 'NOTAS', align: 'start', value: 'desc' }
      ]
      this.botones = [
        { id: 1, nombre: 'NUEVO TIPO EXÁMEN', color: 'primary', accion: 'nuevoTipoExamen' }
      ]
      this.opciones = [
        { id: 1, nombre: 'EDITAR TIPO EXÁMEN', color: 'primary', accion: 'editarTipoExamen' }
        // { id: 2, nombre: 'QUITAR TIPO EXÁMEN', color: 'error', accion: 'quitarTipoExamen' }
      ]
    },
    clicFila (fila) {
      this.campoOpcion = fila.nombre
      this.dialog = true
    },
    clicBoton (accion) {
      if (accion === 'nuevoTipoExamen') {
        this.modoTipoExamen = 'NUEVO'
        this.dialogTipoExamen = true
      } else if (accion === 'editarTipoExamen') {
        this.modoTipoExamen = 'EDITAR'
        this.dialogTipoExamen = true
      } else if (accion === 'quitarTipoExamen') {
        alert('Se quitará éste tipo exámen como parámetro seleccionable')
      }
    },
    cargarItems () {
      this.cargarTabla('examentipos/todo', 'cambiarTabla', 'cargarTablaTiposExamen')
    },
    cargarDatos () {
      if (this.compruebaLogin() === true) {
        if (this.returnPrilegio('tipoexamen') === true) {
          this.interfaz()
          this.cargarItems()
        } else { this.$router.push('/menu') }
      }
    }
  },
  created () {
    this.haySesion()
    this.cargarDatos()
  }
}
</script>

<style scoped>
  .v-data-table.v-data-table.v-data-table >>> td  {
    font-size: 1.0rem !important;
    font-weight:500;
  }
  .v-data-table.v-data-table.v-data-table >>> th  {
    font-size: 1.0rem !important;
    font-weight:500;
    color: cornflowerblue;
  }
</style>
